import { createWithEqualityFn } from 'zustand/traditional';

export const useCatalogStore = createWithEqualityFn<{
    isCatalogPopup: boolean;
    setIsCatalogPopup: (val: boolean) => void;
}>(
    set => ({
        isCatalogPopup: false,
        setIsCatalogPopup(val: boolean) {
            set({ isCatalogPopup: val });
        },
    }),
    Object.is
);
