import { useCallback } from 'react';

import { useBasketAddProduct } from '@api/basket';
import { ProductDetail } from '@api/catalog';

import { useCartContext } from '@context/cart';

import { getItemForMetrics, metricsPushEvent } from '@scripts/helpers';

import { useGetUserData } from './useGetUserData';

export const useChangeQty = (product?: ProductDetail) => {
    const { user } = useGetUserData();

    const { basketProducts, setBasketProducts } = useCartContext();
    const { mutateAsync: setProduct } = useBasketAddProduct();

    return useCallback(
        async (value: number, id: number, setIsLoadingBasket?: (val: boolean) => void) => {
            if (user) {
                if (setIsLoadingBasket) setIsLoadingBasket(true);
                await setProduct([{ offer_id: id, qty: value }]);
            } else {
                if (value) {
                    setBasketProducts(basketProducts.map(item => (item.id === id ? { ...item, qty: value } : item)));
                } else {
                    setBasketProducts(basketProducts.filter(item => item.id !== id));
                }
            }

            metricsPushEvent({
                event: value ? 'add_to_cart' : 'remove_from_cart',
                ecommerce: {
                    items: [
                        {
                            ...(product ? getItemForMetrics(product) : { item_id: id }),
                            quantity: value,
                        },
                    ],
                },
            });
        },
        [user, basketProducts]
    );
};
