import differenceBy from 'lodash/differenceBy';
import {
    Dispatch,
    FC,
    ReactNode,
    ReactNodeArray,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
} from 'react';

import { ProductDetail } from '@api/catalog';
import { getPagination } from '@api/common/helpers';
import { CompareData, useFavorites } from '@api/customers';
import { useMassAddingCompare, useSearchComparedItems } from '@api/customers/compare';

import { useLocalStorage } from '@scripts/hooks';
import { useGetProductsList } from '@scripts/hooks/useGetProductsList';
import { useGetUserData } from '@scripts/hooks/useGetUserData';

import { Favorite } from '../api/customers/types/favorites';

export interface CompareAndFavoriteProps {
    comparedProducts: ProductDetail[];
    setComparedProducts: Dispatch<SetStateAction<ProductDetail[]>>;
    favoriteProductsData: Favorite[];
    isFavoritesLoading: Boolean;
    isLoadingProductsAuth: Boolean;
    comparedAuthProductsIdsData: CompareData[];
}

const CompareAndFavoriteContext = createContext<CompareAndFavoriteProps | null>(null);
CompareAndFavoriteContext.displayName = 'CompareAndFavoriteContext';

export const CompareAndFavoriteProvider: FC<{ children: ReactNode | ReactNodeArray }> = ({ children }) => {
    const { apiUser, error: userError, user } = useGetUserData();

    const [comparedProducts, setComparedProducts] = useLocalStorage<ProductDetail[]>('compareProducts', []);

    const { data: favoritesProducts, isLoading: isFavoritesLoading } = useFavorites(
        {
            sort: 'id',
            pagination: getPagination(-1),
        },
        Boolean(apiUser)
    );

    const favoriteProductsData = useMemo(() => favoritesProducts?.data || [], [favoritesProducts, user]);

    const { data: comparedAuthProductsIds } = useSearchComparedItems(
        {
            sort: 'id',
            pagination: getPagination(-1),
        },
        Boolean(apiUser)
    );

    const comparedAuthProductsIdsData = useMemo(() => comparedAuthProductsIds?.data || [], [comparedAuthProductsIds]);

    const { productsList: authComparedProducts, isLoadingProductsAuth } = useGetProductsList(
        {
            sort: 'id',
            filter: {
                id: comparedAuthProductsIdsData.map(item => item.offer_id),
                with_zero_stock: true,
            },
            pagination: {
                type: 'offset',
                offset: 0,
                //TODO заменить на -1 когда поправят бек #103287
                limit: comparedAuthProductsIdsData.length,
            },
            include: ['brand', 'category', 'images', 'attributes', 'seller', 'nameplates'],
        },
        {
            enabled: false,
            enabledAuth: Boolean(apiUser) && !userError && comparedAuthProductsIdsData.length > 0,
        }
    );

    const { mutateAsync: createMassCompare } = useMassAddingCompare();

    useEffect(() => {
        if (user === null || user === '') return;

        if (authComparedProducts && authComparedProducts.length !== 0) {
            const unsynchronizedOffers = differenceBy(comparedProducts, authComparedProducts, 'id');
            if (unsynchronizedOffers.length !== 0) {
                createMassCompare({
                    offer_ids: unsynchronizedOffers.map(i => i.id),
                });
            }
        } else if (
            comparedAuthProductsIdsData &&
            comparedAuthProductsIdsData.length === 0 &&
            comparedProducts.length > 0
        ) {
            createMassCompare({
                offer_ids: comparedProducts.map(i => i.id),
            });
        }

        setComparedProducts([...(authComparedProducts || [])]);
    }, [authComparedProducts, comparedAuthProductsIdsData]);

    return (
        <CompareAndFavoriteContext.Provider
            value={{
                comparedProducts,
                setComparedProducts,
                favoriteProductsData,
                isFavoritesLoading,
                isLoadingProductsAuth,
                comparedAuthProductsIdsData,
            }}
        >
            {children}
        </CompareAndFavoriteContext.Provider>
    );
};

export const useCompareAndFavoriteContext = () => {
    const context = useContext(CompareAndFavoriteContext);

    if (!context) {
        throw new Error(`Hook useCompareAndFavoriteContext must be used within CompareAndFavoriteProvider`);
    }

    return context;
};
