import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';
import { Menu } from '@api/pages/types/menus';

import { getMenu } from './api';
import './types';

export const useMenu = (data: CommonSearchParams<any>) =>
    useBaseQuery<CommonSearchParams<any>, CommonResponse<Menu[]>, FetchError>({
        data,
        getMethod: getMenu,
    });
