import { useMemo } from 'react';

import { useProducts, useProductsAuth } from '@api/catalog/product';

import { useAuth } from '@context/auth';

import { ITEMS_PER_PRODUCTS_PAGE } from '@scripts/constants';
import { getTotal, getTotalPages } from '@scripts/helpers';

export const useGetProductsList = (
    filters: any = {},
    { enabled = true, enabledAuth = true }: { enabled: boolean; enabledAuth: boolean }
) => {
    const { user } = useAuth();

    const {
        data: apiProductsList,
        isLoading: isLoadingProducts,
        isFetching: isFetchingProducts,
    } = useProducts(filters, enabled);

    const {
        data: apiProductsAuthList,
        isLoading: isLoadingProductsAuth,
        isFetching: isFetchingProductsAuth,
    } = useProductsAuth(filters, enabledAuth);

    const productsList = useMemo(
        () => (user ? apiProductsAuthList?.data : apiProductsList?.data),
        [user, apiProductsList, apiProductsAuthList]
    );

    const totalPages = useMemo(
        () => getTotalPages(user ? apiProductsAuthList : apiProductsList, ITEMS_PER_PRODUCTS_PAGE),
        [user, apiProductsList, apiProductsAuthList]
    );
    const total = useMemo(
        () => getTotal(user ? apiProductsAuthList : apiProductsList),
        [user, apiProductsList, apiProductsAuthList]
    );

    return {
        productsList,
        totalPages,
        isLoadingProducts,
        isLoadingProductsAuth,
        total,
        isFetchingProducts,
        isFetchingProductsAuth,
    };
};
