export enum CustomerStatus {
    CREATED = 1,
    NEW,
    ON_CHECK,
    REJECTED,
    ACTIVE,
    PROBLEMATIC,
    BLOCKED,
    POTENCIAl_REFERAL_PARTNER,
    TEMPORARY_SUSPENDED,
}

export const customerStatusValues = {
    [`${CustomerStatus.CREATED}`]: 'Создан',
    [`${CustomerStatus.NEW}`]: 'Новый (заполнены телефон и портфолио)',
    [`${CustomerStatus.ON_CHECK}`]: 'На проверке',
    [`${CustomerStatus.REJECTED}`]: 'Отклонен',
    [`${CustomerStatus.ACTIVE}`]: 'Активный',
    [`${CustomerStatus.PROBLEMATIC}`]: 'Проблемный',
    [`${CustomerStatus.BLOCKED}`]: 'Заблокирован',
    [`${CustomerStatus.POTENCIAl_REFERAL_PARTNER}`]: 'Потенциальный реферальный партнер',
    [`${CustomerStatus.TEMPORARY_SUSPENDED}`]: 'Временно приостановлен',
};

export enum CustomerGender {
    FEMALE = 1,
    MALE,
}

export const customerGenderValues = {
    [`${CustomerGender.FEMALE}`]: 'Женщина',
    [`${CustomerGender.MALE}`]: 'Мужчина',
};

export enum CustomersTypeEnum {
    ENTITY = 1,
    PHISICAL,
    IP,
}

export enum OrganizationTypeEnum {
    LEGAL = 1,
    INDIVIDUAL = 3,
}

export const OrganizationTypeValues = {
    [OrganizationTypeEnum.LEGAL]: 'LEGAL',
    [OrganizationTypeEnum.INDIVIDUAL]: 'INDIVIDUAL',
};

export enum CustomersTypeValues {
    ENTITY = 'entity',
    PHISICAL = 'phisical',
}

enum CustomerOrgStatusEnum {
    ACTIVE = 1,
    BEING_LIQUIDATED,
    LIQUIDATED,
}

export const CustomerOrgStatusValues: Record<string, string> = {
    [CustomerOrgStatusEnum.ACTIVE]: 'Действующая',
    [CustomerOrgStatusEnum.BEING_LIQUIDATED]: 'Ликвидируется',
    [CustomerOrgStatusEnum.LIQUIDATED]: 'Ликвидирована',
};

enum CustomerTaxSystemEnum {
    USN = 4,
    OSN = 5,
}

export const CustomerTaxSystemValues = {
    [CustomerTaxSystemEnum.USN]: 'Упрощенная система налогообложения (УСН)',
    [CustomerTaxSystemEnum.OSN]: 'Общая система налогообложения (ОСН)',
};

export enum CustomerFileTypesEnum {
    POWER_OF_ATTORNEY = 1,
    LICENSE,
    POWERS,
    STATUTE,
    EGRUL_EXTRACT,
    OGRN,
    INN,
}

export enum VerifyRequestStatus {
    NEW = 1,
    REVIEW,
    APPROVED,
    REJECTED,
}

export enum ADDRESS_CUSTOMER_TYPE {
    ENTITY = 1,
    PHISICAL,
}
