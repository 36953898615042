import { useEffect, useState } from 'react';

import { SCREEN_LG, SCREEN_MD, SCREEN_SM, SCREEN_XL, SCREEN_XS, SCREEN_XXL } from '../constants';

interface MyEventTarget extends EventTarget {
    innerWidth: number;
}

interface MyEvent extends Event {
    target: MyEventTarget;
}

export const useResize = () => {
    const [width, setWidth] = useState(typeof window !== 'undefined' && (window.innerWidth as number));

    useEffect(() => {
        const handleResize = (event: MyEvent): void => {
            setWidth(event.target.innerWidth);
        };
        //@ts-ignore
        window.addEventListener('resize', handleResize);
        return () => {
            //@ts-ignore
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        width,
        isScreenSm: (width as number) >= SCREEN_SM,
        isScreenXs: (width as number) >= SCREEN_XS,
        isScreenMd: (width as number) >= SCREEN_MD,
        isScreenLg: (width as number) >= SCREEN_LG,
        isScreenXl: (width as number) >= SCREEN_XL,
        isScreenXxl: (width as number) >= SCREEN_XXL,
    };
};
