import { CSSObject } from '@emotion/core';
import Link from 'next/link';

import { colors } from '@scripts/gds';

const linkStyles: CSSObject = {
    color: colors.textSecondGrey,
    transition: 'all .2s ease',
    ':hover': {
        color: colors.primary,
    },
};

const PoliticsBlock = ({ closeHandler }: { closeHandler?: () => void }) => (
    <>
        <Link legacyBehavior href="/pages/user-agreement" passHref prefetch={false}>
            <a css={linkStyles} onClick={closeHandler} aria-label={'Пользовательское соглашение'}>
                Пользовательское соглашение
            </a>
        </Link>
        <Link legacyBehavior href="/pages/privacy-policy" passHref prefetch={false}>
            <a css={linkStyles} onClick={closeHandler} aria-label={'Политика конфиденциальности'}>
                Политика конфиденциальности
            </a>
        </Link>
    </>
);

export default PoliticsBlock;
