import { CSSObject } from '@emotion/core';
import { nanoid } from 'nanoid';
import Link from 'next/link';
import { useMemo } from 'react';

import { getPagination } from '@api/common/helpers';
import { useMenu } from '@api/content/menu';

import Carousel from '@components/controls/Carousel';

import { Container, colors, scale } from '@scripts/gds';

import CalculatorIcon from '@icons/20/calculator.svg';

const buttonOptionStyles: CSSObject = {
    height: scale(4),
    padding: `6px ${scale(2)}px !important`,
    borderRadius: scale(9, true),
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    border: `1px solid ${colors.borderMain}`,
};

const HeaderOptions = () => {
    const { data: apiMenu } = useMenu({
        filter: { code: 'header_help' },
        pagination: getPagination(-1),
        include: ['items'],
    });
    const menu = useMemo(
        () =>
            apiMenu?.data
                ? [
                      {
                          id: nanoid(6),
                          url: '/pages/specs',
                          name: 'Просчет спецификации',
                          type: 'grey',
                      },
                      ...apiMenu?.data[0]?.items,
                  ]
                : null,
        [apiMenu]
    );

    return (
        <Container>
            <div css={{ height: scale(4) }}>
                {menu && (
                    <Carousel spaceBetween={scale(3, true)} css={{ width: '100%' }} slidesPerView="auto">
                        {menu.map(item => (
                            <Carousel.Item key={item.id}>
                                <Link legacyBehavior href={item.url} passHref prefetch={false}>
                                    <a
                                        aria-label={item.name}
                                        css={{
                                            ...buttonOptionStyles,
                                            backgroundColor: item.type === 'grey' ? '#E2E9EE' : '#ffffff',
                                            border: item.type === 'grey' ? 'none' : `1px solid ${colors.borderMain}`,
                                        }}
                                    >
                                        {item.type === 'grey' && (
                                            <CalculatorIcon css={{ marginRight: scale(1, false, 10) }} />
                                        )}
                                        {item.name}
                                    </a>
                                </Link>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                )}
            </div>
        </Container>
    );
};
export default HeaderOptions;
