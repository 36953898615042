import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useCatalogStore } from 'src/store';

import { CurrentUser } from '@api/auth/types';
import { useGetBasket } from '@api/basket';

import { useCartContext } from '@context/cart';
import { useCompareAndFavoriteContext } from '@context/compare';

import Badge from '@components/controls/Badge';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';
import { CustomersTypeEnum, STATUSES } from '@scripts/enums';
import { Button, colors, scale } from '@scripts/gds';
import { useGetUserData } from '@scripts/hooks/useGetUserData';
import { sleep } from '@scripts/utils';

import BoxIcon from '@icons/24/box.svg';
import HeartIcon from '@icons/24/heart.svg';
import CartIcon from '@icons/24/shop.svg';
import UserIcon from '@icons/24/user.svg';

const CartPopup = dynamic(() => import('../CartPopup'), { ssr: false });
const AuthPopup = dynamic(() => import('@components/NewAuth'), { ssr: false });

const UserMenu = () => {
    const { push } = useRouter();

    const [isAuthPopup, setIsAuthPopup] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);

    const setIsCatalogPopup = useCatalogStore(({ setIsCatalogPopup }) => setIsCatalogPopup);
    const { user, userData } = useGetUserData();

    const { favoriteProductsData } = useCompareAndFavoriteContext();

    const { data: apiBasketData } = useGetBasket(Boolean(user));

    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);
    const { basketProducts } = useCartContext();

    const numberOfProducts = useMemo(
        () => (user ? basketData.length : basketProducts.length),
        [basketProducts, basketData, user]
    );

    const avatarName = (userData: CurrentUser) => {
        if (userData?.customer_type === CustomersTypeEnum.PHISICAL) {
            const firstName =
                userData?.customer?.first_name?.[0] && userData?.customer?.first_name?.[0] !== null
                    ? userData?.customer?.first_name?.[0]
                    : '';
            const lastName =
                userData?.customer?.last_name?.[0] && userData?.customer?.last_name?.[0] !== null
                    ? userData?.customer?.last_name?.[0]
                    : '';
            return firstName + lastName || 'A';
        }
        if (userData?.customer_type === CustomersTypeEnum.ENTITY || userData?.customer_type === CustomersTypeEnum.IP) {
            return userData?.customer.org_name?.slice(0, 2)?.toUpperCase();
        }
        return '';
    };
    return (
        <>
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: scale(37),
                    height: scale(6),
                }}
            >
                <Button
                    data-testid="button-lk"
                    theme="ghost"
                    size="sm"
                    css={{
                        flexDirection: 'column',
                        padding: '0 !important',
                        justifyContent: 'space-between !important',
                    }}
                    onClick={() => {
                        if (user) {
                            push('/lk/profile');
                            setIsCatalogPopup(false);
                        } else {
                            setIsAuthPopup(true);
                            sleep(1000).then(() => {
                                if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                                    window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'regform_header');
                                }
                            });
                        }
                    }}
                >
                    {user ? (
                        <>
                            {userData?.customer_type !== CustomersTypeEnum.PHISICAL && !userData?.customer.org_name ? (
                                <UserIcon css={{ marginBottom: scale(1, false, 10) }} fill="#4A5661" />
                            ) : (
                                <div
                                    css={{
                                        width: scale(7, true),
                                        height: scale(7, true),
                                        border: `1px solid ${colors.textMainBlack}`,
                                        backgroundColor: colors.textMainBlack,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                        lineHeight: 'inherit',
                                    }}
                                >
                                    <span css={{ color: colors.white }}>{avatarName(userData)}</span>
                                </div>
                            )}
                            Кабинет
                        </>
                    ) : (
                        <>
                            <UserIcon css={{ marginBottom: scale(1, false, 10) }} fill={colors.textMainBlack} />
                            Войти
                        </>
                    )}
                </Button>

                <Button
                    data-testid="button-orders"
                    theme="ghost"
                    size="sm"
                    css={{
                        flexDirection: 'column',
                        padding: '0 !important',
                    }}
                    onClick={() => {
                        if (userData) {
                            push('/lk/orders');
                            setIsCatalogPopup(false);
                        } else {
                            setIsAuthPopup(true);
                        }
                    }}
                >
                    <BoxIcon css={{ fill: colors.textMainBlack, marginBottom: scale(1, false, 10) }} />
                    Заказы
                </Button>

                <Button
                    data-testid="button-favorites"
                    theme="ghost"
                    size="sm"
                    css={{
                        flexDirection: 'column',
                        padding: '0 !important',
                        color: favoriteProductsData.length === 0 ? colors.textNuanceLight : colors.grey900,
                    }}
                    onClick={() => {
                        if (userData) {
                            push('/lk/favorites');
                            setIsCatalogPopup(false);
                        } else {
                            setIsAuthPopup(true);
                        }
                    }}
                >
                    <HeartIcon
                        css={{
                            fill: favoriteProductsData.length === 0 ? colors.textNuanceLight : colors.textMainBlack,
                            marginBottom: scale(1, false, 10),
                        }}
                    />
                    Избранное
                </Button>
                <a
                    href="/cart"
                    onClick={event => {
                        event.preventDefault();
                    }}
                >
                    <Button
                        data-testid="button-busket"
                        theme="ghost"
                        size="sm"
                        onClick={() => {
                            setIsCartOpen(true);
                            if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                                window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'open_basket');
                            }
                        }}
                        css={{
                            flexDirection: 'column',
                            padding: '0 !important',
                            position: 'relative',
                        }}
                    >
                        <CartIcon css={{ fill: colors.textMainBlack, marginBottom: scale(1, false, 10) }} />
                        Корзина
                        {numberOfProducts > 0 && <Badge text={String(numberOfProducts)} type={STATUSES.SUCCESS} />}
                    </Button>
                </a>
            </div>

            <CartPopup
                isOpen={isCartOpen}
                closeHandler={() => setIsCartOpen(false)}
                openAuthPopup={() => {
                    setIsAuthPopup(true);
                }}
            />

            <AuthPopup isOpen={isAuthPopup} closeHandler={() => setIsAuthPopup(false)} />
        </>
    );
};

export default UserMenu;
