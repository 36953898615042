import Link from 'next/link';

import { scale } from '@scripts/gds';

import SocTgIcon from '@icons/24/tg.svg';
import SocVkIcon from '@icons/24/vk.svg';

const SocialMediaBlock = () => (
    <>
        <Link legacyBehavior href="https://vk.com/secumarket" passHref prefetch={false}>
            <a target="_blank" aria-label="vk">
                <SocVkIcon css={{ marginRight: scale(2) }} />
            </a>
        </Link>

        <Link legacyBehavior href="https://t.me/secumarketru" passHref prefetch={false}>
            <a target="_blank" aria-label="telegram">
                <SocTgIcon />
            </a>
        </Link>
    </>
);

export default SocialMediaBlock;
