import { scale } from '@greensight/gds';

import { ColorsTheme, TypographyParam } from '@scripts/gds';

import tokens from '../../../public/tokens.json';

const { colors } = tokens;

export type AccordionTheme = {
    itemBorderColor: keyof ColorsTheme | string;
    separateItemBorder?: string;

    buttonTypography: TypographyParam;
    buttonColor: keyof ColorsTheme | string;
    buttonBg: keyof ColorsTheme | string;
    buttonPadding: string;
    buttonOutlineColor: keyof ColorsTheme | string;
    buttonIconColor: keyof ColorsTheme | string;
    buttonIconTopPosition: string | number;
    buttonIconRightPosition: string | number;
    separateItemBorderRadius?: string | number;
    marginBottom?: string | number;

    pannelPadding: string | number;
    pannelBg: keyof ColorsTheme | string;
};

export interface AccordionThemes {
    [key: string]: AccordionTheme;
}

export const Accordion: AccordionThemes = {
    default: {
        itemBorderColor: 'transparent',

        buttonTypography: 'subheading',
        buttonColor: colors?.textMainBlack,
        buttonBg: 'transparent',
        buttonPadding: `${scale(2)}px 0`,
        buttonOutlineColor: colors?.warning,
        buttonIconColor: colors.textNuanceLight,
        buttonIconTopPosition: '50%',
        buttonIconRightPosition: '0',

        pannelPadding: `${scale(1, true)}px 0`,
        pannelBg: 'transparent',
    },
    links: {
        itemBorderColor: 'transparent',

        buttonTypography: 'buttonReg',
        buttonColor: colors?.textMainBlack,
        buttonBg: 'transparent',
        buttonPadding: `${scale(1, false, 11)}px 0`,
        buttonOutlineColor: 'none',
        buttonIconColor: colors.textMainBlack,
        buttonIconTopPosition: '50%',
        buttonIconRightPosition: '8px',

        pannelPadding: `${scale(1, true)}px 0`,
        pannelBg: 'transparent',
    },
};
