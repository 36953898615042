import {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
    useInfiniteQuery,
    useMutation,
    useQuery,
} from 'react-query';

import { BaseAuthParams } from '@api/types';

import { useAuth } from '@context/auth';

import { HttpCode } from '@scripts/constants';

export const useBaseMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    mutationFn: MutationFunction<TData, { data: TVariables }>,
    options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey' | 'mutationFn'>
): UseMutationResult<TData, TError, TVariables, TContext> => {
    return useMutation(data => mutationFn({ data } as any), options);
};

export const useAuthMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    mutationFn: MutationFunction<TData, { data: TVariables } & BaseAuthParams>,
    options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey' | 'mutationFn'>
): UseMutationResult<TData, TError, TVariables, TContext> => {
    const { user, setUser } = useAuth();
    return useMutation(data => mutationFn({ data, token: user } as any), {
        ...options,
        onError: async (err: any, variables, context) => {
            if (options?.onError) {
                await options.onError(err, variables, context);
            }

            if (err.status === HttpCode.UNAUTHORIZED) {
                setUser('');
            }
        },
    });
};

type TGetMethod<T, TQueryFnData, TQueryKey extends QueryKey = QueryKey> = (data: T) => {
    key: TQueryKey;
    fetch: QueryFunction<TQueryFnData, TQueryKey>;
};

export const useBaseQuery = <
    T,
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>({
    data,
    getMethod,
    ...options
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    data: T;
    getMethod: TGetMethod<T, TQueryFnData, TQueryKey>;
}): UseQueryResult<TData, TError> => {
    const { key, fetch } = getMethod({ ...data });

    return useQuery(key, fetch, {
        ...options,
    });
};

export const useBaseInfiniteQuery = <
    T,
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>({
    data,
    getMethod,
}: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    data: T;
    getMethod: TGetMethod<T, TQueryFnData, TQueryKey>;
}): UseInfiniteQueryResult<TData, TError> => {
    const { key, fetch } = getMethod({ ...data });

    return useInfiniteQuery(key, fetch, {
        getNextPageParam: (lastPage: any) => lastPage?.meta?.pagination?.next_cursor,
    });
};

export const useAuthQuery = <
    T,
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>({
    data,
    getMethod,
    ...options
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    data: T;
    getMethod: TGetMethod<T, TQueryFnData, TQueryKey>;
}): UseQueryResult<TData, TError> => {
    const { user, setUser } = useAuth();
    const token = user || '';
    const { key, fetch } = getMethod({ ...data });

    return useQuery(key, fetch, {
        enabled: !!token,
        ...options,
        onError: async (err: any) => {
            if (options?.onError) {
                await options.onError(err);
            }

            if (err.status === HttpCode.UNAUTHORIZED) {
                setUser('');
            }
        },
    });
};
