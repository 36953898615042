import { apiClient } from '..';
import { BasketProductData } from './types';

export const BASE_BASKET_URL = 'baskets';

export const addProduct = (data: BasketProductData[]) =>
    apiClient.post(`${BASE_BASKET_URL}:set-item`, { data: { items: data } });

export const getBasket = (customer_region_guid: string) =>
    apiClient.post(`${BASE_BASKET_URL}:current`, {
        data: { include: ['images', 'items.service_types'], customer_region_guid },
    });

export const deleteBasket = () => apiClient.delete(BASE_BASKET_URL);
