import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';

import { getSeoTags } from './api';
import { SeoData, SeoFilter } from './types';

export const useSeoTags = (data: CommonSearchParams<SeoFilter>, enabled = true) =>
    useBaseQuery<CommonSearchParams<Partial<SeoFilter>>, CommonResponse<SeoData>, FetchError>({
        data,
        getMethod: getSeoTags,
        enabled,
    });
