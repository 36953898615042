import { Dispatch, FC, ReactNode, ReactNodeArray, SetStateAction, createContext, useContext } from 'react';

import { ProductDetail, ProductService } from '@api/catalog';

import { useLocalStorage } from '@scripts/hooks';

export interface CartContextProps {
    basketProducts: ProductDetail[];
    setBasketProducts: Dispatch<SetStateAction<ProductDetail[]>>;
    basketProductsServices: ProductService[];
    setBasketProductsServices: Dispatch<SetStateAction<ProductService[]>>;
}

const CartContext = createContext<CartContextProps | null>(null);
CartContext.displayName = 'CartContext';

export const CartProvider: FC<{ children: ReactNode | ReactNodeArray }> = ({ children }) => {
    const [basketProducts, setBasketProducts] = useLocalStorage<ProductDetail[]>('basketProducts', []);
    const [basketProductsServices, setBasketProductsServices] = useLocalStorage<ProductService[]>(
        'basketProductsServices',
        []
    );

    return (
        <CartContext.Provider
            value={{ basketProducts, setBasketProducts, basketProductsServices, setBasketProductsServices }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCartContext = () => {
    const context = useContext(CartContext);

    if (!context) {
        throw new Error(`Hook useCart must be used within CartProvider`);
    }

    return context;
};
