import { CSSObject } from '@emotion/core';
import { FC, ReactNode, ReactNodeArray, useCallback, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import { scale, typography, useTheme } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';
import { useResize } from '@scripts/hooks/useResize';

import SuccessIcon from '@icons/20/checkCircle.svg';
import CloseIcon from '@icons/20/closed-new.svg';
import ErrorIcon from '@icons/20/closedCircle.svg';
import InfoIcon from '@icons/20/info.svg';
import WarningIcon from '@icons/20/warning.svg';

export interface ModalProps {
    title?: string;
    message?: string;
    theme?: 'white' | 'dark' | 'error' | 'warning' | 'success';
    icon?: boolean;
    closeBtn?: boolean;
    onClose?: () => void;
    className?: string;
    children?: ReactNode | ReactNodeArray;
    closemodalbuttonsize?: number;
    isNewModal?: boolean;
    isSwipeable?: boolean;
}

const Modal: FC<ModalProps> = ({
    title,
    message,
    theme = 'dark',
    icon,
    onClose,
    closeBtn,
    className,
    closemodalbuttonsize = 9,
    children,
    isNewModal = false,
    isSwipeable = false,
}) => {
    const { isScreenXs } = useResize();
    const { colors } = useTheme();
    const { xs } = useMedia();
    const getIcon = useCallback((t: ModalProps['theme']) => {
        const styles: CSSObject = {
            flexShrink: 0,
            marginRight: scale(1),
            marginTop: scale(1),
        };
        switch (t) {
            case 'dark':
            case 'white':
                return <InfoIcon css={styles} />;
            case 'error':
                return <ErrorIcon css={styles} />;
            case 'warning':
                return <WarningIcon css={styles} />;
            case 'success':
                return <SuccessIcon css={styles} />;
            default:
                return null;
        }
    }, []);

    const getStyles = useCallback(
        (t: ModalProps['theme']) => {
            const styles: CSSObject = {
                fill: 'currentColor',
            };
            switch (t) {
                case 'white':
                    styles.background = colors?.white;
                    styles.color = colors?.grey900;
                    styles.border = `1px solid ${colors?.grey200}`;
                    break;

                case 'error':
                    styles.background = colors?.danger;
                    styles.color = colors?.white;
                    break;
                case 'warning':
                    styles.background = colors?.warning;
                    styles.color = colors?.white;
                    break;
                case 'success':
                    styles.background = colors?.success;
                    styles.color = colors?.white;
                    styles.fill = colors?.white;
                    break;
                case 'dark':
                default:
                    styles.background = colors?.secondaryHover;
                    styles.color = colors?.white;
                    break;
            }
            return styles;
        },
        [
            colors?.danger,
            colors?.grey200,
            colors?.grey900,
            colors?.secondaryHover,
            colors?.success,
            colors?.warning,
            colors?.white,
        ]
    );

    const handlersBox = useSwipeable({
        onSwiped: ({ dir, event }) => {
            event.stopPropagation();

            if (dir === 'Down') {
                onClose && onClose();
            }
        },
        //@ts-ignore lib
        preventDefaultTouchmoveEvent: true,
    });

    const { ref: documentRef } = useSwipeable({
        //@ts-ignore lib
        preventDefaultTouchmoveEvent: true,
    });
    // attach swipeable to document
    useEffect(() => {
        //@ts-ignore lib
        documentRef(document);
        //@ts-ignore lib
        return () => documentRef({});
    });

    return message || title ? (
        <div
            css={{
                display: 'flex',
                paddingLeft: scale(1),
                alignItems: 'flex-start',
                borderRadius: scale(1, true),
                ...getStyles(theme),
            }}
            className={className}
        >
            {icon ? getIcon(theme) : null}
            <div
                {...(isSwipeable && !isScreenXs && { ...handlersBox })}
                css={{
                    flexShrink: 1,
                    flexGrow: 1,
                    padding: `${scale(1)}px 0`,
                    alignSelf: 'stretch',
                    ...typography('bodySm'),
                }}
            >
                {isNewModal && (
                    <div
                        css={{
                            [xs]: {
                                margin: '0 auto',
                                backgroundColor: '#C9D1D6',
                                height: '5px',
                                width: '73px',
                                borderRadius: '3px',
                                marginBottom: '20px',
                            },
                        }}
                    />
                )}
                {title && <p css={typography('bodySmBold')}>{title}</p>}
                {message && <p>{message}</p>}
                {children && <div>{children}</div>}
            </div>
            {closeBtn && onClose ? (
                <button
                    type="button"
                    onClick={onClose}
                    css={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 2,
                        transition: 'opacity ease-in 300ms',
                        ':hover': { opacity: 0.8 },
                        width: scale(closemodalbuttonsize),
                        height: scale(closemodalbuttonsize),
                        color: 'inherit',
                        flexShrink: 0,
                        alignSelf: 'stretch',
                        ...(isNewModal && {
                            [xs]: {
                                display: 'none',
                            },
                        }),
                    }}
                    title="Закрыть"
                >
                    <CloseIcon w/>
                </button>
            ) : null}
        </div>
    ) : null;
};

export default Modal;
