import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCatalogStore, useCityStore, useHeaderStore, usePrepareCity } from 'src/store';

import { getPagination } from '@api/common/helpers';
import { useMenu } from '@api/content/menu';

import { Container, colors, scale, typography } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import LocationIcon from '@icons/20/location.svg';
import MailIcon from '@icons/mail.svg';

const CityPopup = dynamic(() => import('@components/CityPopup'), { ssr: false });
const GeoPositionPopup = dynamic(() => import('@components/GeoPosition'), { ssr: false });

const TopHeader = () => {
    const [isGeoPositionPopup, setIsGeoPostionPopup] = useState(true);
    const [isCityPopup, setIsCityPopup] = useState(false);
    const { lg } = useMedia();
    const { isFirstTime } = usePrepareCity();

    const setIsFixed = useHeaderStore(({ setIsFixed }) => setIsFixed);
    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const setIsCatalogPopup = useCatalogStore(({ setIsCatalogPopup }) => setIsCatalogPopup);

    const headerRef = useRef(null);

    useEffect(() => {
        let observer: IntersectionObserver;

        observer = new IntersectionObserver(([entry]) => {
            setIsFixed(!entry.isIntersecting);
        });

        if (headerRef?.current) {
            observer.observe(headerRef.current);
        }

        return () => {
            setIsFixed(false);

            if (observer) {
                observer.disconnect();
            }
        };
    }, []);

    const { data: apiMenu } = useMenu({
        filter: { code: 'header_main' },
        pagination: getPagination(-1),
        include: ['items'],
    });
    const menu = useMemo(() => (apiMenu?.data ? apiMenu?.data[0]?.items : null), [apiMenu]);

    return (
        <div css={{ backgroundColor: '#2A3641', [lg]: { display: 'none' } }} ref={headerRef}>
            <Container
                css={{
                    height: scale(5),
                    ...typography('bodyXSRegular'),
                    color: colors.textNuanceLight,
                }}
            >
                <div
                    css={{
                        height: '100%',
                        display: 'flex',
                    }}
                >
                    <div
                        css={{
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            minWidth: 'fit-content',
                            width: scale(1, false, 349),
                        }}
                    >
                        <LocationIcon css={{ marginRight: scale(1, false, 10) }} />
                        <button css={{ color: colors.white }} onClick={() => setIsCityPopup(true)}>
                            {currentCity.city}
                        </button>
                        {isGeoPositionPopup && isFirstTime && (
                            <GeoPositionPopup
                                isOpen={isGeoPositionPopup}
                                onChangeToDefineCity={() => {
                                    setIsGeoPostionPopup(false);
                                    setIsCityPopup(true);
                                }}
                                onClose={() => {
                                    setIsGeoPostionPopup(false);
                                }}
                            />
                        )}
                    </div>

                    <div
                        css={{
                            display: 'flex',
                            color: colors.white,
                            alignItems: 'center',
                            gap: scale(3),
                        }}
                    >
                        <Link
                            href="tel:+7(495)792-19-94"
                            css={{ transition: 'color 0.3s ease', ':hover': { color: colors.secondary } }}
                            prefetch={false}
                        >
                            +7 (495) 792-19-94
                        </Link>

                        <Link
                            href="mailto:support@secumarket.ru"
                            css={{
                                display: 'flex',
                                alignItems: 'center',
                                transition: 'color 0.3s ease',
                                ':hover': { color: colors.secondary },
                            }}
                            prefetch={false}
                        >
                            <MailIcon css={{ marginRight: scale(3, true) }} /> Напишите нам
                        </Link>
                    </div>

                    <div
                        css={{
                            color: colors.white,
                            display: 'flex',
                            alignItems: 'center',
                            gap: scale(2),
                            marginLeft: 'auto',
                            [lg]: { gap: scale(1) },
                        }}
                    >
                        {menu?.map(item => (
                            <Link legacyBehavior key={item.id} href={item.url} passHref prefetch={false}>
                                <a onClick={() => setIsCatalogPopup(false)} aria-label={item.name}>
                                    {item.name}
                                </a>
                            </Link>
                        ))}
                    </div>
                </div>
            </Container>

            <CityPopup isOpen={isCityPopup} closeHandler={() => setIsCityPopup(false)} />
        </div>
    );
};

export default TopHeader;
