import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';

import { getContentPages } from './api';
import { PageDetail, PageFilters } from './types/pages';

export const useContentPages = (data: CommonSearchParams<Partial<PageFilters>>, enabled = true) =>
    useBaseQuery<CommonSearchParams<Partial<PageFilters>>, CommonResponse<PageDetail[]>, FetchError>({
        data,
        getMethod: getContentPages,
        enabled,
    });
