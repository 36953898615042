import { useTheme } from '@greensight/gds';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import Media from 'react-media';

import { getPagination } from '@api/common/helpers';
import { useMenu } from '@api/content/menu';

import PoliticsBlock from '@components/PoliticsBlock';
import SocialMediaBlock from '@components/SocialMediaBlock';

import { Layout, Section, colors, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import PopSbIcon from '@icons/big/pop-sb.svg';
import LogoIcon from '@icons/market-logo.svg';

const LinksBlock = dynamic(() => import('./LinksBlock'));

export default function FooterCopyRights() {
    const { query } = useRouter();
    const defaultMatches = query?.viewport ? query.viewport === 'desktop' : false;

    const { sm, md, mdMin, lg } = useMedia();
    const { layout } = useTheme();

    const { data: apiMenu } = useMenu({
        filter: { code: 'footer_main' },
        pagination: getPagination(-1),
        include: ['items'],
    });
    const menu = useMemo(() => (apiMenu?.data ? apiMenu?.data[0]?.items_tree : null), [apiMenu]);

    return (
        <Section>
            <Layout
                cols={{ xxxl: 24, sm: 7 }}
                css={{
                    padding: `${scale(10)}px 0 ${scale(9, true)}px`,
                    [md]: { padding: `${scale(4)}px 0 ${scale(4)}px` },
                    [sm]: { gridGap: `0px !important` },
                }}
            >
                <Layout.Item
                    col={{ xxxl: 8, sm: 7 }}
                    css={{
                        [md]: { display: 'grid', gridAutoFlow: 'row', gridGap: scale(4) },
                    }}
                >
                    <LogoIcon css={{ marginBottom: scale(4), [md]: { marginBottom: 0 } }} />
                    <div
                        css={{
                            width: '349px',
                            borderRadius: '6px',
                            border: '1px solid #E9EFF2',
                            padding: '12px 20px',
                            marginBottom: '32px',
                            color: '#728895',
                            fontSize: '12px',
                            lineHeight: '14px',
                            letterSpacing: '0.018em',
                            fontWeight: 400,
                            [lg]: { width: '304px' },
                            [md]: { marginBottom: '0px' },
                        }}
                    >
                        <div
                            css={{
                                display: 'flex',
                                justifyContent: 'space-beetwen',
                                alignItems: 'center',
                            }}
                        >
                            <div>
                                При партнерстве консорциума «Производители охранных, пожарных, СКУД систем безопасности»
                            </div>
                            <div>
                                <PopSbIcon />
                            </div>
                        </div>
                    </div>
                    <div
                        css={{
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridGap: scale(3),
                        }}
                    >
                        <div
                            css={{
                                width: '100%',
                                color: colors.textSecondGrey,
                            }}
                        >
                            © {new Date().getFullYear()}, Secumarket.ru — отраслевой маркетплейс систем безопасности.
                        </div>
                        <Media query={{ maxWidth: layout?.breakpoints.md || 1024 }} defaultMatches>
                            {matches =>
                                matches && (
                                    <div css={{ width: '100%' }}>
                                        <SocialMediaBlock />
                                    </div>
                                )
                            }
                        </Media>

                        <div
                            css={{
                                display: 'flex',
                                width: '100%',
                                gap: scale(2),
                                [sm]: { flexDirection: 'column' },
                                [mdMin]: { display: 'none' },
                            }}
                        >
                            <PoliticsBlock />
                        </div>
                    </div>
                </Layout.Item>

                <Media query={{ minWidth: 1023 }} defaultMatches={defaultMatches}>
                    {matches => <>{matches && <LinksBlock menu={menu} />}</>}
                </Media>
            </Layout>
        </Section>
    );
}
