import getConfig from 'next/config';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Media from 'react-media';

import { scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import FooterCopyRights from './FooterCopyRights';
import FooterLinks from './FooterLinks';
import FooterSubscribe from './FooterSubscribe';

export default function Footer() {
    const { pathname, query } = useRouter();
    const { sm } = useMedia();
    const defaultMatches = query?.viewport ? query.viewport !== 'desktop' : false;

    const { publicRuntimeConfig } = getConfig();

    return (
        <footer css={{ [sm]: { ...(query?.categories?.includes('product') && { paddingBottom: scale(12) }) } }}>
            {!pathname.includes('lk') && <FooterSubscribe />}
            <FooterLinks />
            <Media query={{ maxWidth: 1023 }} defaultMatches={defaultMatches}>
                {matches => !matches && <FooterCopyRights />}
            </Media>

            {publicRuntimeConfig?.environment !== 'localhost' && publicRuntimeConfig?.environment !== 'DEV' && (
                <>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-8JR94RCQB4"></script>
                    <Script id="gtag" strategy="afterInteractive">
                        {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-8JR94RCQB4');`}
                    </Script>
                </>
            )}
        </footer>
    );
}
