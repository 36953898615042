import { jsx } from '@emotion/core';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikValues } from 'formik';
import { ElementType, ReactNode } from 'react';

import { Form } from '@components/controls/Form';
import { FormMessageProps } from '@components/controls/Form/Message';

import { scale, typography, useTheme } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';
import { MergeElementProps } from '@scripts/utils';

export interface LegendBaseProps {
    /** Name for form (inner) */
    name?: string;
    /** Label for Legend */
    label?: string | ReactNode | null;
    /** Flag required for forms */
    required?: boolean;
    /** Hint for legend */
    hint?: string;
    /** Formik meta object (inner) */
    meta?: FieldMetaProps<any>;
    /** All values from Formik */
    values?: FormikValues;
    /** Formik helpers (inner) */
    helpers?: FieldHelperProps<any>;
    /** Type for form */
    type?: string;
    /** Formik field object (inner) */
    field?: FieldInputProps<any>;
    /** Success text */
    success?: string;
    /** Show message flag */
    showMessage?: boolean;
    /** Custom message text */
    messageText?: string;
    /** Message type */
    messageType?: FormMessageProps['type'];
    /** Custom error position */
    customError?: boolean;
}

export type LegendProps<P extends ElementType = 'label'> = {
    /** Use your own React component for render. */
    as?: P;
} & MergeElementProps<P, LegendBaseProps>;

export const Legend = <T extends ElementType = 'label'>({
    as,
    label,
    required = false,
    hint,
    meta,
    name,
    showMessage,
    messageType = 'warning',
    messageText = 'Есть изменения',
    customError = false,
    ...props
}: LegendProps<T>) => {
    const { colors } = useTheme();
    delete props.id;
    delete props.field;
    delete props.helpers;

    const { xs } = useMedia();

    return jsx(
        as || 'label',
        { htmlFor: name, css: { display: 'block', position: 'relative', paddingBottom: scale(1, true) }, ...props },
        <>
            <div
                css={
                    required && {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        [xs]: { flexDirection: 'row-reverse', justifyContent: 'start' },
                    }
                }
            >
                {label && typeof label === 'string' ? (
                    <span css={{ ...typography('bodyXXSRegular'), color: colors?.textNuanceLight }}>{label}</span>
                ) : (
                    label
                )}
                {required && (
                    <span
                        css={{
                            alignSelf: 'start',
                            position: 'relative',
                            top: scale(1),
                            color: colors?.actionRed,
                            [xs]: { top: 0 },
                        }}
                    >
                        *
                    </span>
                )}
            </div>
            {hint && (
                <div css={{ ...typography('bodySm'), color: colors?.grey800, marginTop: scale(1, true) }}>{hint}</div>
            )}
            {!customError && meta?.error && meta?.touched && (
                <Form.Message message={meta.error} css={{ marginTop: scale(1, true) }} />
            )}
            {!customError && !(meta?.error && meta?.touched) && showMessage ? (
                <Form.Message message={messageText} type={messageType} css={{ marginTop: scale(1, true) }} />
            ) : null}
        </>
    );
};

export default Legend;
