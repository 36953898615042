import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useAuthQuery, useBaseQuery } from '@api/hooks';

import { ProductDetail, ProductFilter, ProductsFilterItem } from '../types';
import { getProductDetail, getProductDetailAuth, getProductFilters, getProducts, getProductsAuth } from './api';

export const useProducts = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) =>
    useBaseQuery<CommonSearchParams<Partial<ProductFilter>>, CommonResponse<ProductDetail[]>, FetchError>({
        data,
        getMethod: getProducts,
        enabled,
    });

export const useProductsAuth = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) =>
    useAuthQuery<CommonSearchParams<Partial<ProductFilter>>, CommonResponse<ProductDetail[]>, FetchError>({
        data,
        getMethod: getProductsAuth,
        enabled,
    });

export const useProductDetail = (data: { id: string | number; include?: string }, enabled = true) =>
    useBaseQuery<{ id: string | number; include?: string }, CommonResponse<ProductDetail>, FetchError>({
        data,
        getMethod: getProductDetail,
        enabled,
    });

export const useProductAuthDetail = (data: { id: string | number; include?: string }, enabled = true) =>
    useAuthQuery<{ id: string | number; include?: string }, CommonResponse<ProductDetail>, FetchError>({
        data,
        getMethod: getProductDetailAuth,
        enabled,
    });

export const useProductsFilters = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) =>
    useBaseQuery<CommonSearchParams<Partial<ProductFilter>>, CommonResponse<ProductsFilterItem>, FetchError>({
        data,
        getMethod: getProductFilters,
        enabled,
    });
