import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CommonResponse, CommonSearchParams } from '@api/common/types';

import { FetchError, apiClient } from '..';
import { CompareData, MassCompareMutated } from './types/compare';

const COMPARE_URL = 'customers/compared-offers';
export const COMPARE_KEY = 'compared';

export const useSearchComparedItems = (data: CommonSearchParams<CompareData>, enabled = true) =>
    useQuery<CommonResponse<CompareData[]>, FetchError>({
        queryKey: [COMPARE_KEY, data],
        queryFn: () => apiClient.post(`${COMPARE_URL}:search`, { data }),
        enabled,
    });

export const useAddingCompare = () => {
    const queryClient = useQueryClient();

    return useMutation<CommonResponse<CompareData>, FetchError, CompareData>(
        data => apiClient.post(COMPARE_URL, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries(COMPARE_KEY),
        }
    );
};

export const useDeleteCompare = () => {
    const queryClient = useQueryClient();

    return useMutation<CommonResponse<null>, FetchError, CompareData>(
        data => apiClient.delete(`${COMPARE_URL}/${data.id}`, { data }),
        { onSuccess: () => queryClient.invalidateQueries(COMPARE_KEY), retry: 2 }
    );
};

export const useMassAddingCompare = () => {
    const queryClient = useQueryClient();

    return useMutation<CommonResponse<CompareData>, FetchError, MassCompareMutated>(
        data => apiClient.post(`${COMPARE_URL}:mass-create`, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries(COMPARE_KEY),
        }
    );
};
