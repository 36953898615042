export enum SERVICES_ENUM {
    MONTAGE = 1,
    PROJECTING,
}

export enum SERVICE_STATUSES_ENUM {
    AWAITING_FOR_CUSTOMERS = 1,
    CUSTOMER_WAS_NOT_CHOSEN = 10,
    CUSTOMER_WAS_CHOSEN = 20,
    CLOSED = 30,
}

export const SERVICE_STATUSES_VALUES = {
    [SERVICE_STATUSES_ENUM.AWAITING_FOR_CUSTOMERS]: 'Ждем предложений подрядчиков',
    [SERVICE_STATUSES_ENUM.CUSTOMER_WAS_NOT_CHOSEN]: 'Исполнитель не выбран',
    [SERVICE_STATUSES_ENUM.CUSTOMER_WAS_CHOSEN]: 'Исполнитель выбран',
    [SERVICE_STATUSES_ENUM.CLOSED]: 'Закрыта',
};
