import { createWithEqualityFn } from 'zustand/traditional';

export const useHeaderStore = createWithEqualityFn<{
    isFixed: boolean;
    setIsFixed: (val: boolean) => void;
}>(
    set => ({
        isFixed: false,
        setIsFixed(val: boolean) {
            set({ isFixed: val });
        },
    }),
    Object.is
);
