import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export interface CityItem {
    city: string;
    city_guid: string;
    city_kladr: string;
    country_code: string;
    post_index: string;
    region: string;
    region_guid: string;
    geo_lat: string;
    geo_lon: string;
    address_string: string;
    street?: string;
    house?: string;
}

export const useCityStore = createWithEqualityFn<{
    currentCity: CityItem;
    setCurrentCity: (val: CityItem) => void;
}>(
    set => ({
        currentCity: {
            city: 'Москва',
            address_string: 'г Москва, Красная пл, д 9',
            city_guid: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
            city_kladr: '7700000000000',
            country_code: 'RU',
            post_index: '109012',
            region: 'Москва',
            region_guid: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
            geo_lat: '55.7522',
            geo_lon: '37.6198822',
            street: 'Красная',
            house: '9',
        },
        setCurrentCity(city: CityItem) {
            set({ currentCity: city });
        },
    }),
    Object.is
);

export const usePrepareCity = () => {
    const [isFirstTime, setFirstTime] = useState(false);
    const { setCurrentCity } = useCityStore(
        ({ currentCity, setCurrentCity }) => ({
            currentCity,
            setCurrentCity,
        }),
        shallow
    );
    const [cookies] = useCookies(['city']);

    useEffect(() => {
        if (cookies.city) {
            setCurrentCity(cookies.city);
            return;
        } else {
            setFirstTime(true);
        }
    }, []);

    return { isFirstTime };
};
