import { CommonSearchParams } from '@api/common/types';

import { apiClient } from '..';
import { BannersFilter } from './types';

const API_URL = 'cms/banners';
const QUERY_KEY = 'banners';

export const getBanners = (data: CommonSearchParams<Partial<BannersFilter>>) => ({
    key: [QUERY_KEY, data],
    fetch: () =>
        apiClient.post(`${API_URL}:search`, {
            data,
        }),
});

const API_MENU_URL = 'cms/menus';
const QUERY_MENU_KEY = 'menu';

export const getMenu = (data: CommonSearchParams<any>) => ({
    key: [QUERY_MENU_KEY, data],
    fetch: () =>
        apiClient.post(`${API_MENU_URL}:search`, {
            data,
        }),
});
