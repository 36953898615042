export enum ImageTypes {
    BASE = 1,
    CATALOG,
    GALLERY,
    DESCRIPTION,
}

export enum ProductStatePopup {
    SAVED = 'saved',
    DELETE = 'delete',
    DELETE_SUCCESS = 'deleteSuccess',
    LEAVE = 'leave',
}

export enum PRICE_UNITS {
    PIECE = 'piece',
    KG = 'kg',
    GRAMM = '100_g',
    METR = 'm',
    METR2 = 'm2',
    COIL = 'coil',
    PARTS = 'package',
}

export const PRICE_UNITS_ENUM_VALUES = {
    [PRICE_UNITS.PIECE]: '<span>шт</span>',
    [PRICE_UNITS.KG]: '<span>кг</span>',
    [PRICE_UNITS.GRAMM]: '<span>100г</span>',
    [PRICE_UNITS.METR]: '<span>м</span>',
    [PRICE_UNITS.METR2]: '<span>м<sup>2</sup></span>',
    [PRICE_UNITS.COIL]: '<span>бухта</span>',
    [PRICE_UNITS.PARTS]: '<span>уп</span>',
};

export enum TYPES_ENUM {
    PIECE = 1,
    KG,
    PARTS,
}

export const TYPES_ENUM_VALUES: Record<string, string> = {
    [TYPES_ENUM.PIECE]: 'шт',
    [TYPES_ENUM.KG]: 'кг',
    [TYPES_ENUM.PARTS]: 'уп',
};

export enum VIEW_OPTIONS {
    DEFAULT = 'square',
    LINES = 'lines',
    SMALL_LINES = 'small-lines',
    TABLE = 'table',
}

export enum TAG_COLOR {
    RED = 1,
    YELLOW,
    VIOLET,
    GREEN,
}
