import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';

import { CategoryDetail, CategorySearchItem, CategoryTreeItem, ProductFilter, VirtualCategory } from '../types';
import { getCategories, getCategoriesTree, getCategoryDetail, getVirtualCategoryDetail } from './api';

export const useCategoriesTree = (enabled = true) =>
    useBaseQuery<null, CommonResponse<CategoryTreeItem[]>, FetchError>({
        data: null,
        getMethod: getCategoriesTree,
        enabled,
    });

export const useCategories = (data: CommonSearchParams<any>) =>
    useBaseQuery<CommonSearchParams<any>, CommonResponse<CategorySearchItem[]>, FetchError>({
        data,
        getMethod: getCategories,
    });

export const useCategoryDetail = (
    data: { id?: number; code?: string; offers_filter?: Partial<ProductFilter>; include?: string[] },
    enabled = true
) =>
    useBaseQuery<{ id?: number; code?: string }, CommonResponse<CategoryDetail>, FetchError>({
        data,
        getMethod: getCategoryDetail,
        enabled,
    });

export const useVirtualCategoryDetail = (
    data: { filter: { id?: number; code?: string }; include?: string[] },
    enabled = true
) =>
    useBaseQuery<
        { filter: { id?: number; code?: string }; include?: string[] },
        CommonResponse<VirtualCategory>,
        FetchError
    >({
        data,
        getMethod: getVirtualCategoryDetail,
        enabled,
    });
