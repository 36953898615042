import { CSSObject } from '@emotion/core';

import { STATUSES } from '@scripts/enums';
import { scale, typography, useTheme } from '@scripts/gds';

export interface BadgeProps {
    text: string;
    type?: STATUSES;
    customCSS?: CSSObject;
}

const Badge = ({ text, type, customCSS }: BadgeProps) => {
    const { colors } = useTheme();
    let backgroundColor;
    switch (type) {
        case STATUSES.CREATED: {
            backgroundColor = colors?.primary;
            break;
        }
        case STATUSES.SUCCESS: {
            backgroundColor = colors?.secondary;
            break;
        }
        case STATUSES.ERROR: {
            backgroundColor = colors?.danger;
            break;
        }
        case STATUSES.WARNING: {
            backgroundColor = colors?.warning;
            break;
        }
        default: {
            backgroundColor = colors?.secondaryHover;
        }
    }

    return (
        <div
            css={{
                position: 'absolute',
                top: -scale(1, true),
                right: scale(1, true),
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor,
                color: colors?.white,
                height: scale(2),
                padding: `${scale(1, true)}px ${scale(1, false, 6)}px`,
                borderRadius: scale(1, true),
                whiteSpace: 'pre-line',
                ...typography('smallBold'),
                ...customCSS,
            }}
        >
            {text}
        </div>
    );
};

export default Badge;
