import { CommonSearchParams } from '@api/common/types';
import { apiClient } from '@api/index';

import { SeoFilter } from './types';

const QUERY_KEY_SEO = 'seo';
const API_URL = 'seo';

export const getSeoTags = (data: CommonSearchParams<Partial<SeoFilter>>) => ({
    key: [QUERY_KEY_SEO, data],
    fetch: () =>
        apiClient.post(`${API_URL}:search-one`, {
            data,
        }),
});
