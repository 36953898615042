import { CSSObject } from '@emotion/core';
import { useField } from 'formik';
import { HTMLProps, ReactNode, useCallback, useMemo, useState } from 'react';

import { SVGRIcon } from '@customTypes/index';

import BasicField from './BasicField';
import { FormMessageProps } from './Message';

export interface TypedFieldProps extends Omit<HTMLProps<HTMLInputElement>, 'label' | 'ref' | 'onChange'> {
    /** Input name */
    name: string;
    /** Label for Legend */
    label?: string | ReactNode;
    /** legend visible flag */
    isLegend?: boolean;
    /** hint */
    hint?: string;
    /** Icon */
    Icon?: SVGRIcon;
    /** icon styles */
    iconCss?: CSSObject;
    /** class name */
    className?: string;
    /** Show message flag */
    showMessage?: boolean;
    /** Custom message text */
    messageText?: string;
    /** Message type */
    messageType?: FormMessageProps['type'];
    /** onChange handler */
    onChange?: (value: string) => void;
    fieldType?: 'positiveInt' | 'positiveFloat';
}

const DEFAULT_VALUE = '';

export const TypedField = ({
    name,
    value,
    label,
    isLegend = false,
    hint,
    Icon,
    iconCss,
    className,
    showMessage,
    messageText,
    messageType = 'warning',
    onChange,
    fieldType,
    ...props
}: TypedFieldProps) => {
    const [field, meta, helpers] = useField(name);

    const inputProps = {
        type: 'text',
        name,
        ...props,
    };

    const controlled = helpers || onChange;
    const controlledValue = field?.value ?? value ?? DEFAULT_VALUE;

    const [stateValue, setStateValue] = useState(DEFAULT_VALUE);

    const inputValue = useMemo(
        () => (controlled ? controlledValue : stateValue),
        [controlled, controlledValue, stateValue]
    );

    const onChangeHander = useCallback(
        (val: string) => {
            let parsedVal = val;
            if (fieldType === 'positiveInt') {
                parsedVal = val.replace(/[^\d]+/g, '');
            }

            if (fieldType === 'positiveFloat') {
                parsedVal = val.replace(/[^.\d]+/g, '').replace(/^([^.]*\.)|\./g, '$1');
            }
            if (parsedVal !== inputValue) {
                if (controlled) {
                    if (helpers) helpers.setValue(parsedVal);
                    if (onChange) onChange(parsedVal);
                } else {
                    setStateValue(parsedVal);
                }
            }
        },
        [controlled, helpers, inputValue, onChange, fieldType]
    );

    return (
        <div css={{ width: '100%' }} className={className}>
            <BasicField
                label={label}
                isLegend={isLegend}
                hint={hint}
                Icon={Icon}
                iconCss={iconCss}
                field={field}
                meta={meta}
                showMessage={showMessage}
                messageText={messageText}
                messageType={messageType}
                {...inputProps}
                {...props}
                onChange={e => onChangeHander(e.currentTarget.value)}
            />
        </div>
    );
};

export default TypedField;
