import { CSSObject } from '@emotion/core';
import {
    ComponentsTheme,
    Theme,
    createTheme,
    typography as gdsTypography,
    useTheme as useGDSTheme,
} from '@greensight/gds';

import tokens from '../../public/tokens.json';
import { Accordion, AccordionThemes } from './themes/accordion';
import { Button } from './themes/button';
import { Calendar, CalendarTheme } from './themes/calendar';
import { global } from './themes/global';
import { Input, InputThemes } from './themes/input';
import { Select, SelectTheme } from './themes/select';
import { Tabs, TabsTheme } from './themes/tabs';

interface ComponentsThemeExtended extends ComponentsTheme {
    Calendar?: CalendarTheme;
    Tabs?: TabsTheme;
    Input?: InputThemes;
    Select?: SelectTheme;
    Accordion?: AccordionThemes;
}

export const { colors, shadows } = tokens;
export type ColorsTheme = typeof colors;
export type TypographyParam = keyof typeof tokens.typography.styles;

export interface ExtendedTheme extends Omit<Theme, 'colors'> {
    components?: ComponentsThemeExtended;
    colors?: ColorsTheme;
}

const settings: ExtendedTheme = {
    global,
    components: {
        Button,
        Calendar,
        Tabs,
        Input,
        Select,
        Accordion,
    },
};

const theme = createTheme({
    tokens,
    settings,
}) as ExtendedTheme;

const typography = (name: TypographyParam = 'bodySm') => gdsTypography(name, theme) as CSSObject;
const useTheme = () => useGDSTheme() as ExtendedTheme;

export * from '@greensight/gds';
export { typography, theme, useTheme };
