import dynamic from 'next/dynamic';
import { useMemo, useState } from 'react';
import Media from 'react-media';

import { useCategoriesTree } from '@api/catalog/categories';
import Popup from '@components/controls/Popup';

import { colors, scale, useTheme } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';
import CatalogPopupTitle from './CatalogPopupTitle';

const CatalogMobile = dynamic(() => import('./CatalogMobile'), { ssr: false });
const CatalogDesktop = dynamic(() => import('./CatalogDesktop'), { ssr: false });

const CatalogPopup = ({ isOpen, closeHandler }: { isOpen: boolean; closeHandler: () => void }) => {
    const { layout } = useTheme();
    const { lg } = useMedia();

    const { data: apiDataCategories, error, isLoading } = useCategoriesTree();
    const categoriesTree = useMemo(() => apiDataCategories?.data || [], [apiDataCategories]);

    const [title, setTitle] = useState('Каталог товаров');
    const [resetToFirstLevel, setResetToFirstLevel] = useState(false);

    const catalogPopupTitleProps = {
        title,
        setTitle,
        setResetToFirstLevel,
        closeHandler, // Передаем closeHandler
    };

    return (
        <Media query={{ minWidth: layout?.breakpoints.lg || 1280 }} defaultMatches={false}>
            {matches =>
                !error &&
                categoriesTree &&
                !isLoading && (
                    <Popup
                        isOpen={isOpen}
                        onRequestClose={closeHandler}
                        isCloseButton={false} // Отключаем стандартную кнопку закрытия
                        css={{
                            maxWidth: 'none',
                            height: '100%',
                            [lg]: { '& > p': { paddingLeft: scale(2) } },
                        }}
                        isSwipeable
                        isCatalog
                        isFullscreen
                        title={!matches ? <CatalogPopupTitle {...catalogPopupTitleProps} /> : ''}
                        scrollInside={matches}
                    >
                        <Popup.Body
                            css={{
                                borderTop: `5px solid ${colors.backgroundMain}`,
                                height: '100%',
                                padding: 0,
                                [lg]: { borderTop: 'none', paddingBottom: scale(1, false, 52) },
                            }}
                        >
                            {matches ? (
                                <CatalogDesktop closeHandler={closeHandler} categoriesTree={categoriesTree} />
                            ) : (
                                <CatalogMobile
                                    closeHandler={closeHandler}
                                    categoriesTree={categoriesTree}
                                    setTitle={setTitle}
                                    resetToFirstLevel={resetToFirstLevel}
                                    setResetToFirstLevel={setResetToFirstLevel}
                                />
                            )}
                        </Popup.Body>
                    </Popup>
                )
            }
        </Media>
    );
};

export default CatalogPopup;
